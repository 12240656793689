<template>
  <div>
    <video ref="videoPlayer" controls style="width: 100%;height: 90vh; margin: auto 0;">
      <source :src="videoUrl" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</template>


<script>
export default {
  data() {
    return {
      videoUrl: "http://124.70.80.208:3000/image/xcxxt.mp4" // 视频文件路径，可以从数据属性或 props 中获取
    };
  },
  mounted() {
    // 初始化视频播放器s
    const videoPlayer = this.$refs.videoPlayer;
    videoPlayer.load();
  },
  methods: {
    playVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.play(); // 开始播放视频
    },
    pauseVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.pause(); // 暂停视频
    }
  }
};
</script>


<style>
</style>